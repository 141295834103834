import { Button, Col, Container, Div, Image, Row, StyleReset, Text, ThemeProvider } from "atomize"
import PageTransition from "gatsby-plugin-page-transitions"
import { Link } from "gatsby"
import React, { useEffect } from "react"
import Footer from "../../components/footer"
import "../../settings/index.css"
import Header from "../../components/header"

import logoNoText from "../../images/squiseat-logo-ufficiale-senza-testo.png"
import SEO from "../../components/seo"
let config = require("../../settings/config")

const theme = config.theme
const url = config.url

const FAQEntry = props => {
    return (
        <Div pos="relative" p={{ b: { xs: "4rem", lg: "5rem" } }}>
            <Container>
                <Text textColor="accent" textSize={{ xs: "title", lg: "heading" }} textWeight="bold" textAlign="left">
                    {props.question}
                </Text>
                <Text textColor="accent_dark" textSize={{ xs: "title", lg: "heading" }} textAlign="left">
                    {props.answer}
                </Text>
            </Container>
        </Div>
    )
}

const FaqUser = () => {
    return (
        <PageTransition>
            <ThemeProvider theme={theme}>
                <StyleReset />

                <Header />
                <SEO
                    title="Le Domande Frequenti fatte dai nostri clienti"
                    description="Sei un cliente? Hai domande sul nostro servizio antispreco? In questa pagina trovi le risposte alle Domande Frequenti che ci rivolgono i nostri clienti."
                    pathname="faq-clienti"
                />
                <main style={{ position: "relative", overflow: "hidden" }}>
                    <section>
                        <Div
                            bg="white"
                            pos="relative"
                            p={{
                                t: {
                                    xs: "0",
                                    md: "1rem",
                                    lg: "6rem",
                                    xl: "7rem",
                                },
                            }}
                            m={{ y: "1rem" }}
                        >
                            <Text tag="h1" d="none">
                                Le domande frequenti dei nostri clienti
                            </Text>
                            <Text tag="h2" d="none">
                                Quali metodi di pagamento accetta Squiseat?
                            </Text>
                            <Text tag="h2" d="none">
                                Quando posso ritirare un ordine?
                            </Text>
                            <Text
                                textColor="primary"
                                textAlign="center"
                                textSize={{
                                    xs: "display1",
                                    md: "display2",
                                    lg: "display3",
                                }}
                                p={{ y: "2rem" }}
                                textWeight="900"
                            >
                                Domande frequenti degli utenti
                            </Text>

                            <FAQEntry
                                question="Come posso pagare su Squiseat?"
                                answer={
                                    <span>
                                        E’ possibile pagare con qualsiasi tipologia di carta, Paypal o Satispay.
                                    </span>
                                }
                            />

                            <FAQEntry
                                question="Quando posso ritirare un ordine?"
                                answer={
                                    <span>
                                        Puoi scegliere la fascia oraria che preferisci tra quelle messe a disposizione
                                        dal locale dal quale hai ordinato.
                                    </span>
                                }
                            />

                            <FAQEntry
                                question="Effettuate la consegna a domicilio?"
                                answer={
                                    <span>
                                        No, attualmente è possibile solamente ritirare i prodotti in negozio.
                                        <br />
                                        In questo modo, evitiamo di collaborare con fattorini non pagati adeguatamente o
                                        aumentare a dismisura i costi del nostro servizio e non renderlo accessibile a
                                        tutti.
                                    </span>
                                }
                            />

                            <FAQEntry
                                question="Quanto costano i prodotti?"
                                answer={
                                    <span>
                                        Con Squiseat, le rimanenze vengono vendute al 50% del prezzo di listino.
                                        {/*, mentre
                                        i prodotti in prenotazione sono a prezzo pieno!*/}
                                    </span>
                                }
                            />

                            <FAQEntry
                                question="I prodotti sono buoni e sicuri?"
                                answer={
                                    <span>
                                        Certo! Sono prodotti che il negoziante ha ancora in vendita in negozio, ma che
                                        probabilmente non riuscirà a vendere prima che perdano di qualità.
                                    </span>
                                }
                            />

                            <Div pos="relative" p={{ t: { xs: "6rem", lg: "7rem" } }}>
                                <Container>
                                    <Text
                                        textColor="primary"
                                        textSize={{
                                            xs: "display1",
                                            md: "display2",
                                            lg: "display3",
                                        }}
                                        textWeight="bold"
                                    >
                                        Possiedi un locale e hai dei dubbi?
                                    </Text>
                                    <Text
                                        textColor="primary"
                                        textSize={{
                                            xs: "title",
                                            md: "heading",
                                            lg: "display1",
                                        }}
                                    >
                                        Qui puoi trovare delle risposte
                                    </Text>
                                    <Link to={url.faqSupp} title="Ho un locale | Squiseat">
                                        <Button
                                            w={{ xs: "12.5rem", md: "15rem" }}
                                            h={{ xs: "3rem", lg: "4rem" }}
                                            m={{ x: "auto", t: "3rem" }}
                                            bg="white"
                                            border="2px solid"
                                            borderColor="primary"
                                            rounded="circle"
                                        >
                                            <Text
                                                textColor="primary"
                                                textSize={{
                                                    xs: "subheader",
                                                    md: "title",
                                                }}
                                            >
                                                FAQ
                                            </Text>
                                        </Button>
                                    </Link>
                                </Container>
                            </Div>
                        </Div>
                    </section>
                </main>
                <Footer />
            </ThemeProvider>
        </PageTransition>
    )
}

export default FaqUser
